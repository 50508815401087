.Form {
  margin-bottom: 16px;

  &__InputContainer {
    label {
      margin-right: 8px;
      font-weight: bold;
    }

    > * {
      padding-bottom: 5px;
      padding-right: 5px;
    }
  }
}
