.ProductLeakLocation {
  margin-bottom: 5px;
  padding: 5px 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 200px;
  background-color: white;

  font-weight: bold;
  font-size: smaller;

  &--EnableHover {
    &:hover {
      background: yellow;
    }
  }

  &__Index {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    text-align: center;
    vertical-align: middle;
    font-size: larger;
    line-height: 22px;
    margin-right: 8px;
  }

  &__Label {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
  }

  &--Failure {
    white-space: nowrap;
  }
}
