body {
  display: flex;
  flex-direction: column;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Open Sans", sans-serif;
}

p {
  margin-bottom: 0.5rem;
}

label {
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;

  &__nav {
    display: flex;
    align-items: center;

    > * {
      margin: 10px 10px 10px 10px;
    }
  }

  &__main {
    flex: 1;
    align-self: stretch;
    // max-width: 980px;
    margin: 15px;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
    padding: 5px 10px;
    font-size: smaller;
    > * {
      text-overflow: hidden;
      word-wrap: break-word;
    }
  }

  /* Hide spinner which appears inside  input elements with type=number */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .indent {
    width: 72px;
  }
}
